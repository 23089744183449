<template lang="pug">
.main-wrapper.caixas-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "caixas" }'> Caixas</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } caixa`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nm_caixa.$error }")
                                label.form-label Nome:
                                InputText(type='text' v-model='$v.model.nm_caixa.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_caixa.$error')
                                    .form-message--error(v-if="!$v.model.nm_caixa.maxLength") <b>Nome do Caixa</b> deve ter no máximo 30 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_caixa.minLength") <b>Nome do Caixa</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_caixa.required") <b>Nome do Caixa</b> é obrigatório.

                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                                label.form-label Tipo de Caixa:
                                Dropdown(v-model='$v.model.ie_tipo.$model' :options='options.ie_tipo' :disabled='Boolean(model.id)'
                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' @change='() => { changeFilters(); model.ie_tipo === "S" ? filters.ie_supervisao = false : filters.ie_supervisao = true; getSalas(true) }')
                                .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                    .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Tipo de caixa</b> é obrigatório.
                            .p-col-6
                                label.form-label Id Terminal TEF:
                                InputText(type='text' v-model='model.id_terminal_paygo')
                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_unidades_medclub.$error }")
                                label.form-label Sala(s):
                                CustomMultiSelect(v-model='$v.model.cd_unidades_medclub.$model' :disabled='control.ieDisablePosto'
                                    :options='options.salas' dataKey='value' optionLabel='labelFilter' optionValue='value' display='chip' placeholder='Selecione' filter)
                                    template(#label='props')
                                        span {{ findSalaById(props.value) }}
                                    template(#option='props')
                                        span <b> Sala: {{ props.option.label }} </b> <br>
                                            span <b> Setor: </b> {{ props.option.labelAlt1 }} / <b> Unidade: </b> {{ props.option.labelAlt2 }}
                                .feedback--errors(v-if='submitted && $v.model.cd_unidades_medclub.$error')
                                    .form-message--error(v-if="!$v.model.cd_unidades_medclub.required") <b>Sala(s)</b> é obrigatório.
                                    .form-message--error(v-if="!$v.model.cd_unidades_medclub.tipoCaixaValidator") Caixa Operacional e Totem não pode estar relacionado a mais de um posto.

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_usuarios.$error }")
                                label.form-label Usuário(s):
                                ProgressBar(v-if='!options.cd_usuarios.length')
                                CustomMultiSelect(v-else v-model='$v.model.cd_usuarios.$model' :disabled='control.isDisableUsuario' :options='options.cd_usuarios'
                                    dataKey='value' optionLabel='text' optionValue='value' display='chip' placeholder='Selecione' filter)
                                .feedback--errors(v-if='submitted && $v.model.cd_usuarios.$error')
                                    .form-message--error(v-if="!$v.model.cd_usuarios.required") <b>Usuário(s)</b> é obrigatório.

                            .p-col-12
                                label.form-label Forma(s) de pagamento:
                                ProgressBar(v-if='!options.formasPagamentoOption.length')
                                CustomMultiSelect(v-else v-model='model.cd_formas_pagamento' :options='options.formasPagamentoOption'
                                    dataKey='value' optionLabel='text' optionValue='value' display='chip' placeholder='Selecione')

                            .p-col-12(v-if="!model.id && !control.isDisableValorInicial")
                                label.form-label Valor Inicial:
                                .p-inputgroup
                                    span.p-inputgroup-addon R$
                                    span
                                        currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                            v-model='model.nr_valor' locale='pt-BR' :auto-decimal-mode='true' :value-range='{ min: 0 }')
                                        .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                                            .form-message--error(v-if="!$v.model.nr_valor.valorInicialValidator") <b>Valor inicial</b> só está disponivel para caixas do tipo supervisão.

                            .p-col-12.mt-2.footer-infos(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.mt-2
                                Button(label='Salvar' type="submit")


</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .footer-infos {
            font-size: 12px;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'

    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import { UnidadesMedclub, Caixas, DominioValor, Usuario} from './../../middleware'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import moment from "moment"
    import CustomMultiSelect from '../CustomComponents/CustomMultiSelect.vue'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip, CustomMultiSelect
        },
        directives: { tooltip: Tooltip },
        async created () {
            this.waiting = true

            DominioValor.findAll({ds_mnemonico: ['GRUPOS_PERMITIDOS', 'TIPO_CAIXA']}).then(response => {
                if (response.status == 200){
                    response.data['TIPO_CAIXA'].valores.forEach((un) => {
                        if(un.ie_valor != 'F') {
                            this.options.ie_tipo.push({ value: un.ie_valor, text: un.ds_valor})
                        }
                        response.data['GRUPOS_PERMITIDOS'].valores.forEach((item) => {
                            if(item.ie_valor == un.ds_valor.toUpperCase().replace("Ã", "A")) {
                                this.options.aux_tipo_caixa[un.ie_valor] = item.ds_valor
                            }
                        })
                    });
                }
            })

            Caixas.getFormasPagamento().then(response => {
                if (response.status === 200) {
                    response.data.forEach((un) => {
                        this.options.formasPagamentoOption.push({ value: un.id, text: un.nm_forma_pagamento })
                    });
                }
            })


            // buscar caixa especifico (fluxo de edicao)
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                Caixas.find(id).then(response => {
                    if (response.status == 200) {
                        this.model.id = id
                        this.model.nm_caixa = response.data.nm_caixa
                        this.model.ie_tipo = response.data.ie_tipo
                        this.model.ie_tipo === "S" ? this.filters.ie_supervisao = false : this.filters.ie_supervisao = true
                        this.model.id_terminal_paygo = response.data.id_terminal_paygo
                        if(this.model.ie_tipo === "S") response.data.cd_unidades_medclub.forEach(e => {
                            this.options.salas.push({label: e.nm_sala, labelAlt1: e.nm_setor, labelAlt2: e.nm_unidade, value: e.id, labelFilter: e.nm_sala + e.nm_setor + e.nm_unidade})
                        })
                        this.getSalas(false)

                        response.data.cd_unidades_medclub.forEach(e => {
                            this.model.cd_unidades_medclub.push(e.id)
                        })
                        this.model.cd_formas_pagamento = response.data.cd_formas_pagamento
                        this.model.cd_usuarios = response.data.cd_usuarios
                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        if (response.data.dt_atualizado) {
                            this.model.nm_usuario_edi = response.data.nm_usuario_edi
                            this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        }

                        this.changeFilters()

                        this.control.ieDisablePosto = this.model.ie_tipo != "S"
                    }
                    this.waiting = false
                })
            } else {
                this.waiting = false
            }
        },
        data () {
            return {
                model: {
                    id_terminal_paygo: '',
                    nm_caixa: '',
                    ie_tipo: null,
                    cd_unidades_medclub: [],
                    cd_formas_pagamento: [],
                    cd_usuarios: [],
                    nr_valor: 0
                },
                options: {
                    aux_tipo_caixa: {},
                    salas: [],
                    ie_tipo: [],
                    cd_usuarios: [],
                    formasPagamentoOption: []
                },
                filters: {
                    cd_grupo_list: [],
                    ie_supervisao: null,
                    cd_usuarios: [],
                },
                control: {
                    ieDisablePosto: true,
                    isDisableUsuario: true,
                    isDisableValorInicial: true,
                },
                waiting: true,
                waitingForm: false,
                submitted: false,
            }
        },
        validations () {
            let v = {
                model: {
                    nm_caixa: { required, minLength: minLength(2), maxLength: maxLength(30) },
                    cd_unidades_medclub: {
                        required,
                        tipoCaixaValidator(value) {
                            if (this.model.ie_tipo && ["O", "T"].includes(this.model.ie_tipo) && value.length > 1) {
                                return false
                            }
                            return true
                        }
                    },
                    ie_tipo: {required},
                    cd_usuarios: {required},
                    nr_valor: {
                        valorInicialValidator(value) {
                            if (value && this.model.ie_tipo && this.model.ie_tipo != "S") {
                                return false
                            }
                            return true
                        }
                    }
                }
            }

            return v
        },
        watch: {
            "model.ie_tipo": function(value) {
                this.options.cd_usuarios = []
                this.control.isDisableUsuario = true
                this.control.isDisableValorInicial = value != "S"
            },
        },
        methods: {
            findSalaById(id) {
                return this.options.salas.find(item => {
                    return item.value == id
                })?.label || ''
            },
            getSalas(apagar) {
                if (apagar) {
                    this.options.salas = []
                    this.model.cd_unidades_medclub = []
                }
                UnidadesMedclub.findAllSala({ie_restrito: false, ie_tipo:'ATD', ie_supervisao: this.filters.ie_supervisao}).then(response=>{
                    if (response.status == 200) {
                        response.data.forEach(e => {
                            this.options.salas.push({label: e.nm_sala, labelAlt1: e.nm_setor, labelAlt2: e.nm_unidade, value: e.id, labelFilter: e.nm_sala + e.nm_setor + e.nm_unidade})
                        })
                        this.control.ieDisablePosto = false
                    }
                })
            },
            changeFilters() {
                if(this.model.ie_tipo){
                    this.filters.cd_grupo_list = this.options.aux_tipo_caixa[this.model.ie_tipo]

                    Usuario.findUserBySafe(this.filters).then(response => {
                        if(response.status == 200) {
                            this.options.cd_usuarios = []
                            this.control.isDisableUsuario = false
                            response.data.forEach((un) => {
                                this.options.cd_usuarios.push({
                                    value: un.id,
                                    text: un.first_name + ' ' + un.last_name + ' ' + '(' + un.username + ')'
                                })
                            })

                            // remover usuarios invalidos previamente selecionados
                            if (this.model.cd_usuarios && this.model.cd_usuarios.length > 0) {
                                let setUsuarios = new Set(this.model.cd_usuarios);
                                this.model.cd_usuarios = this.options.cd_usuarios.filter(user => setUsuarios.has(user.value)).map(user => user.value);
                            }
                        }
                    })
                }
                else this.control.isDisableUsuario = true

            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0
                let dataSend = Object.assign({}, this.model)
                this.waitingForm = true

                Caixas.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'caixas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.detail)
                            response.data.detail.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            async getDominio() {
                return DominioValor.findAll({ds_mnemonico: ['GRUPOS_PERMITIDOS', 'TIPO_CAIXA']}).then(response => {
                    if (response.status == 200){
                        response.data['TIPO_CAIXA'].valores.forEach((un) => {
                            if(un.ie_valor != 'F') {
                                this.options.ie_tipo.push({ value: un.ie_valor, text: un.ds_valor})
                            }
                            response.data['GRUPOS_PERMITIDOS'].valores.forEach((item) => {
                                if(item.ie_valor == un.ds_valor.toUpperCase().replace("Ã", "A")) {
                                    this.options.aux_tipo_caixa[un.ie_valor] = item.ds_valor
                                }
                            })
                        });
                    }  
                })
            },
            async getFormasPagamento() {
                return Caixas.getFormasPagamento().then(response => {
                    if (response.status === 200) {
                        response.data.forEach((un) => {
                            this.options.formasPagamentoOption.push({ value: un.id, text: un.nm_forma_pagamento })
                        });
                    }
                })
            }
         }
      }
</script>
